<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="content-wrapper">
          <h2 class="text-center title">Weights and Dimensions</h2>
          <div class="content-box">
            <div class="heading">
              <h3>Volumetric Weight Calculation</h3>
              <p>
                When dimensional weight exceeds actual weight, shipments will be charged according
                to the IATA volumetric standard.
              </p>
            </div>
            <div class="row text-center">
              <div class="col-md-6">
                <h5 class="">Volumetric Weight Calculation Domestic USA</h5>
                <div class="image-wrapper">
                  <img
                    src="/img/weight-demension/usa-weight-cal.jpg"
                    class="img-fluid img-thumbnail"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <h5>Volumetric Weight Calculation International</h5>
                <div class="image-wrapper text-center">
                  <img
                    src="/img/weight-demension/international-weight-cal.jpg"
                    class="img-fluid img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 text-justify">
                For example, for U.S. or Canadian origin export shipments measured in inches, the
                divisor is 166. For EMEA, LAC and APAC origin export shipments measured in inches,
                the divisor is 139; if measured in centimeters, the divisor is 5,000.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeightDimensions',
  created() {
    document.title =
      'Trepr - Calculate the Volumetric Weight | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', 'Know more about Volumetric Weight Calculation Here');
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, Trepr Calculate the Volumetric Weight,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
